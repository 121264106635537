import { Controller } from "@hotwired/stimulus";
import noUiSlider from 'nouislider';
export default class extends Controller {
    static targets = ["slider", "amount", "buttons", "monthlyDose", "monthlyIncome"];

    connect() {
        // Initialize noUiSlider with specified range and step
        this.slider = noUiSlider.create(this.sliderTarget, {
            start: 20000, // Default value
            range: {
                'min': 1500, // Minimum value
                'max': 25000 // Maximum value
            },
            connect: 'lower',
            tooltips: false, // No tooltip
            step: 500, // Step size
            format: {
                to: function(value) {
                    return value.toFixed(0); // Format value without currency here
                },
                from: function(value) {
                    return Number(value); // Convert formatted value back to number
                }
            }
        });

        // Set default year value
        this.selectedYears = 7;

        // Update the amount displayed next to the slider
        this.slider.on('update', (values, handle) => {
            this.amountTarget.textContent = `${Number(values[handle]).toLocaleString()} €`;
            this.calculateLoanDetails(); // Recalculate when the slider changes
        });

        this.calculateLoanDetails(); // Initial calculation
    }

    selectYears(event) {
        // Update selected year value
        this.selectedYears = parseInt(event.currentTarget.dataset.value);

        console.log(this.buttonsTargets)
        // Update button styles
        this.buttonsTargets.forEach(button => {
            button.classList.remove('text-zinc-950', 'shadow', 'bg-white'); // Remove active styles
            button.classList.add('text-zinc-500'); // Add inactive styles
        });

        // Add active styles to the clicked button
        event.currentTarget.classList.add('text-zinc-950', 'shadow', 'bg-white');
        event.currentTarget.classList.remove('text-zinc-500');

        this.calculateLoanDetails(); // Recalculate when years change
    }
    calculateLoanDetails() {
        const interestRate = 5.0; // Example interest rate, replace with actual value
        const lawContribution = 0.6;

        const loanAmountValue = parseFloat(this.slider.get()) || 0;
        const loanYearsValue = this.selectedYears || 0;

        const loanMonthsValue = loanYearsValue * 12;

        const finalInterestRate = interestRate + lawContribution;
        const monthlyInterest = finalInterestRate / 12 / 100;
        const x = Math.pow(1 + monthlyInterest, loanMonthsValue);
        const monthlyPayment = (loanAmountValue * x * monthlyInterest) / (x - 1) || 0;

        // Assuming you have some way of getting the monthly payment display value (like from another input)
        const monthlyPaymentDisplay = 1000; // Replace with actual value if needed

        this.monthlyDoseTarget.textContent = `${monthlyPayment.toFixed(2).replace(".", ",")} €`;
        // this.monthlyIncomeTarget.textContent = `${(monthlyPaymentDisplay - monthlyPayment).toFixed(2).replace(".", ",")} €`;
    }
}